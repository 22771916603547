import { Box, Flex, Container, Heading } from "@theme-ui/components"
import React from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import { InboundLink } from "../components/link"
import Seo from "../components/seo"
import { i18nContext, languages } from "../context/i18nContext"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"

const NotFoundPage = context => {
  const locale = "it"
  const i18nPaths = []

  return (
    <i18nContext.Provider value={languages[locale]}>
      <LanguageSwitcherContext.Provider
        value={{ activeLocale: locale, paths: i18nPaths || [] }}
      >
        <Seo title="404: Not found" />
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "100vh",
          }}
        >
          <Header color={"dark"} />
          <Box sx={{ mt: [8, 8, 8, 11], mb: [8, 8, 8, 11] }}>
            <Container>
              <Heading variant="h1" as="h1" sx={{ mb: 4 }}>
                404: Pagina non trovata
              </Heading>
              <InboundLink to="/">Torna all'home page</InboundLink>
            </Container>
          </Box>
          <Footer />
        </Flex>
      </LanguageSwitcherContext.Provider>
    </i18nContext.Provider>
  )
}

export default NotFoundPage
